import axios from 'axios';

const BASE_URL = process.env.VUE_APP_APIURL;

const get = () => {
    if (get.cache) return Promise.resolve(get.cache);
    return axios.get(`${BASE_URL}/api/TransferStatuses`)
    .then((r) => {
        const cache = r.data.map((i) => ({ Value: i.Id, Text: i.Name }));
        get.cache = cache;
        return cache;
    })
    .catch((err) => console.log(err));
};

export default {
    get,
};
