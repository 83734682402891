import axios from 'axios';

const BASE_URL = process.env.VUE_APP_APIURL;

const get = (term = '', isNotQuick) => axios.get(`${BASE_URL}/api/Municipalities?term=${term}&isNotQuick=${isNotQuick}`)
    .then((r) => r.data.map((i) => ({ Value: i.Id, Text: i.Name })))
    .catch((err) => console.log(err));

const getMunicipality = (id) => axios.get(`${BASE_URL}/api/Municipalities/${id}`);
const updateMunicipality = (id, model) => axios.put(`${BASE_URL}/api/Municipalities/${id}`, model);
const addMunicipality = (model) => axios.post(`${BASE_URL}/api/Municipalities`, model);
const getTransferBotTypes = () => axios.get(`${BASE_URL}/api/TransferBotTypes`)
    .then((r) => r.data.map((t) => ({ Value: t.Id, Text: t.Name })));

export default {
    get,
    getMunicipality,
    updateMunicipality,
    addMunicipality,
    getTransferBotTypes
};
