import axios from 'axios';

const BASE_URL = process.env.VUE_APP_APIURL;

const get = () => axios.get(`${BASE_URL}/api/TollRoads`).then((r) => r.data.map((i) => ({ Value: i.Id, Text: i.Name })));
const getUploadStatus = (guid) => axios.get(`${BASE_URL}/api/TollRoadReports/Batch/${guid}/Status`);
const getBatch = (batchId) => axios.get(`${BASE_URL}/api/TollRoadReports/Batch/${batchId}`);

export default {
    get,
    getUploadStatus,
    getBatch,
};
